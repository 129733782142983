<template>
  <div>
    <b-row>
      <b-col class="d-flex align-items-center">
        <h1 class="font-weight-bolder mr-1">
          {{ $t('new', { type: $t('expense') }) }}
        </h1>
        <span>
          {{ showExpenseTypeCatalogHistory }}
        </span>
      </b-col>
    </b-row>
    <validation-observer ref="expensesValidation">
      <b-form @submit.prevent="validateForm()">
        <b-card
          v-for="(record, index) in expense.expense_records"
          :key="index"
          no-body
          class=""
        >
          <b-card-header
            class="p-0 mt-1 mx-1 d-flex align-items-start justify-content-between"
          >
            <span class="h4 text-primary d-flex">
              {{ $t('expense') }} {{ index + 1 }}
            </span>
            <b-card-text class="font-medium-6 mb-0">
              <feather-icon
                icon="XIcon"
                size="15"
                class="cursor-pointer ml-1"
                @click="spliceExpense(index)"
              />
            </b-card-text>
          </b-card-header>
          <b-row>
            <b-col>
              <b-card-body class="pt-0">
                <b-row>
                  <b-col
                    sm="12"
                    lg="12"
                  >
                    <b-form-group :label="$t('expenseType')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('expenseType')"
                      >
                        <expense-type-menu
                          :index="index"
                          :expense-type-catalogs="expenseTypeCatalogs"
                          :record="record"
                          :history="expenseTypeCatalogHistory"
                          @refetch="fetchExpensesTypeCatalogs($route.params.projectId)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-if="materialCatalogId == record.expense_type_catalog_id"
                  class="align-items-center"
                >
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group :label="$t('material')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="$t('material')"
                      >
                        <b-form-input
                          v-show="false"
                          v-model="record.name"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <div class="">
                        <vue-bootstrap-typeahead
                          v-model="productQuery"
                          :placeholder="$t('search')"
                          size="lg"
                          :data="[...productsResults, ...groupedExpenses]"
                          :serializer="item => item.name"
                          :show-all-results="true"
                          :max-matches="5"
                          @hit="selectProduct($event, index)"
                        >
                          <template
                            slot="suggestion"
                            slot-scope="{ data }"
                          >
                            <div>
                              <span
                                class="font-weight-bolder mr-1"
                                style="font-size: 14px"
                              >[{{ data.sku }}]</span>
                              <span style="font-size: 12px">{{
                                data.name
                              }}</span>
                            </div>
                          </template>
                        </vue-bootstrap-typeahead>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="3"
                  >
                    <b-form-group :label="$t('supplier')">
                      <vue-bootstrap-typeahead
                        v-model="record.supplier"
                        :placeholder="$t('search')"
                        size="lg"
                        :data="suppliersResults"
                        :serializer="name => name"
                        :max-matches="6"
                      >
                        <template
                          slot="suggestion"
                          slot-scope="{ data }"
                        >
                          <div>
                            <span>{{ data }}</span>
                          </div>
                        </template>
                      </vue-bootstrap-typeahead>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="3"
                  >
                    <b-button
                      v-b-modal.newMaterial
                      size="lg"
                      class="mt-1"
                      variant="outline-primary"
                      block
                      @click="newMaterialIndex = index"
                    >{{ $t('newMaterial') }}</b-button>
                  </b-col>
                  <b-col
                    v-if="record.name"
                    sm="12"
                  >
                    <b-card
                      no-body
                      class="mb-1 mt-1 mt-lg-0 py-1 border-secondary"
                      :class="{
                        'w-50': !isSmallScreen,
                        'w-100': isSmallScreen,
                      }"
                    >
                      <b-card-body class="py-0">
                        <div class="d-flex justify-content-between">
                          <div v-if="record.name">
                            <span class="font-weight-bolder">
                              {{ $t('name') }}:
                            </span>
                            {{ record.name }}
                          </div>
                          <feather-icon
                            icon="XIcon"
                            size="15"
                            class="cursor-pointer ml-1"
                            color="rgb(255,0,0)"
                            @click="removeProduct(index)"
                          />
                        </div>
                        <div v-if="record.brand">
                          <span class="font-weight-bolder">
                            {{ $t('brand') }}:
                          </span>
                          {{ record.brand }}
                        </div>
                        <div v-if="record.description">
                          <span class="font-weight-bolder">
                            {{ $t('description') }}:
                          </span>
                          {{ record.description }}
                        </div>
                        <div v-if="record.sku">
                          <span class="font-weight-bolder">
                            SKU:
                          </span>
                          {{ record.sku }}
                        </div>
                        <div v-if="record.unit_type">
                          <span class="font-weight-bolder">
                            {{ $t('unit') }}:
                          </span>
                          {{ $tc(unitsFilter(record.unit_type)) }}
                        </div>
                      </b-card-body>
                    </b-card>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="3"
                  >
                    <b-form-group :label="$t('quantity')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('quantity')"
                        rules=""
                      >
                        <b-form-input
                          v-model="record.quantity"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="number"
                          step=".01"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="3"
                  >
                    <b-form-group :label="$t('unitPrice')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('unitPrice')"
                        rules=""
                      >
                        <b-input-group
                          prepend="$"
                          size="lg"
                        >
                          <money
                            v-model="record.unit_price"
                            v-bind="money"
                            class="font-weight-bolder px-1 flex-fill form-control"
                          />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="2"
                  >
                    <b-form-group :label="$t('iva')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('iva')"
                        rules=""
                      >
                        <b-form-input
                          v-model="record.iva_percentage"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="2"
                  >
                    <b-form-group :label="$t('subtotal')">
                      <b-input-group
                        prepend="$"
                        size="lg"
                      >
                        <money
                          disabled
                          v-bind="money"
                          :value="record.unit_price * record.quantity"
                          class="font-weight-bolder px-1 flex-fill form-control"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="2"
                  >
                    <b-form-group :label="$t('total')">
                      <b-input-group
                        prepend="$"
                        size="lg"
                      >
                        <money
                          disabled
                          v-bind="money"
                          :value="
                            record.unit_price * record.quantity +
                              record.unit_price *
                              record.quantity *
                              (record.iva_percentage / 100)
                          "
                          class="font-weight-bolder px-1 flex-fill form-control"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="3"
                  >
                    <b-form-group :label="$t('date')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('date')"
                        rules=""
                      >
                        <b-form-datepicker
                          v-model="record.expense_date"
                          :date-format-options="{
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          size="lg"
                          class="font-weight-bolder"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="3"
                    class=""
                  >
                    <b-form-group :label="$t('status')">
                      <b-dropdown
                        id="dropdown-1"
                        :text="$t(record.expense_status)"
                        variant="transparent"
                        class="m-md-2"
                        :class="backgroundForStatus(record.expense_status)"
                        size="lg"
                      >
                        <b-dropdown-item
                          v-for="(status, key) in expenseStatusOptions"
                          :key="key"
                          @click="setStatus(record, status.value)"
                        >
                          {{ $t(status.value) }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-else-if="
                    materialCatalogId != record.expense_type_catalog_id &&
                      record.expense_type_catalog_id
                  "
                  class="align-items-center"
                >
                  <b-col
                    sm="12"
                    lg="3"
                  >
                    <b-form-group :label="$t('date')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('date')"
                        rules=""
                      >
                        <b-form-datepicker
                          v-model="record.expense_date"
                          :date-format-options="{
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          size="lg"
                          class="font-weight-bolder"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="3"
                  >
                    <b-form-group :label="$t('supplier')">
                      <b-form-input
                        v-model="record.supplier"
                        size="lg"
                        class="qty-field"
                        autocomplete="off"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="3"
                    class=""
                  >
                    <b-form-group :label="$t('status')">
                      <b-dropdown
                        id="dropdown-1"
                        :text="$t(record.expense_status)"
                        variant="transparent"
                        class="m-md-2"
                        :class="backgroundForStatus(record.expense_status)"
                        size="lg"
                      >
                        <b-dropdown-item
                          v-for="(status, key) in expenseStatusOptions"
                          :key="key"
                          @click="setStatus(record, status.value)"
                        >
                          {{ $t(status.value) }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group :label="$t('description')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('description')"
                        rules=""
                      >
                        <b-form-input
                          v-model="record.description"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="2"
                  >
                    <b-form-group :label="$t('quantity')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('quantity')"
                        rules=""
                      >
                        <b-form-input
                          v-model="record.quantity"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="2"
                  >
                    <b-form-group :label="$t('unitPrice')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('unitPrice')"
                        rules=""
                      >
                        <b-form-input
                          v-model="record.unit_price"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="2"
                  >
                    <b-form-group :label="$t('iva')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('iva')"
                        rules=""
                      >
                        <b-form-input
                          v-model="record.iva_percentage"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="2"
                  >
                    <b-form-group :label="$t('subtotal')">
                      <b-input-group
                        prepend="$"
                        size="lg"
                      >
                        <money
                          disabled
                          v-bind="money"
                          :value="record.unit_price * record.quantity"
                          class="font-weight-bolder px-1 flex-fill form-control"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="2"
                  >
                    <b-form-group :label="$t('total')">
                      <b-input-group
                        prepend="$"
                        size="lg"
                      >
                        <money
                          disabled
                          v-bind="money"
                          :value="
                            record.unit_price * record.quantity +
                              record.unit_price *
                              record.quantity *
                              (record.iva_percentage / 100)
                          "
                          class="font-weight-bolder px-1 flex-fill form-control"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="primary"
              size="lg"
              class="mr-2"
              @click="pushExpense"
            >
              {{ $t('addAnotherExpenses') }}
            </b-button>
            <b-button
              variant="primary"
              size="lg"
              class="px-5"
              type="submit"
            >
              {{ $t('save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="newMaterial"
      :title="$t('newMaterial')"
      @ok="selectNewMaterial()"
      @cancel="newMaterial = {}"
      @hide="newMaterialIndex = null"
    >
      <b-row>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('name')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.name"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('supplier')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.supplier"
              min="1"
              class="qty-field"
              autocomplete="off"
            />
          </b-form-group>
        </b-col> -->
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('brand')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.brand"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('description')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.description"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'underscore'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ExpenseTypeMenu from './ExpenseTypeMenu.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ExpenseTypeMenu,
  },

  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      expense: {
        project_id: this.$route.params.projectId,
        name: '',
        brand: '',
        description: '',
        expense_records: [
          {
            expense_type_catalog_id: '',
            name: '',
            quantity: 0,
            unit_price: 0,
            iva_percentage: 16,
            supplier: '',
            brand: '',
            description: '',
            sku: '',
            expense_status: 'paid',
            expense_date: '',
            expense_create_type: this.$route.query.createType,
          },
        ],
      },
      newMaterialIndex: null,
      expenseStatusOptions: [
        { value: 'paid' },
        { value: 'credit' },
        { value: 'expired' },
        { value: 'received' },
      ],
      productQuery: '',
      productsResults: [],
      suppliersResults: [],
      newMaterial: {},
      materialCatalogId: null,
      required,
      expenseTypeCatalogHistory: [],
    }
  },
  computed: {
    showExpenseTypeCatalogHistory() {
      return this.expenseTypeCatalogHistory
        .map(category => category.name)
        .join(' / ')
    },
    totalAmount() {
      return (
        +this.expense.subtotal
          + this.expense.subtotal * (+this.expense.iva / 100) || ''
      )
    },
    ...mapGetters('groupedExpenses', [
      'expenseTypeCatalogs',
      'groupedExpenses',
    ]),
    ...mapGetters('app', ['unitsFilter', 'isSmallScreen']),
  },
  watch: {
    productQuery: _.debounce(function search(query) {
      if (!query.length) return
      this.fetchProducts({
        by_name: query,
        by_active_status: true,
        meta: {
          pagination: {
            per_page: 10,
          },
        },
      }).then(response => {
        this.productsResults = JSON.parse(JSON.stringify(response.data))
      })
      // this.loading = false
    }, 500),
  },
  async mounted() {
    await this.fetchExpensesTypeCatalogs(this.$route.params.projectId).then(response => {
      this.materialCatalogId = response.data.find(
        expense => expense.name === 'Material',
      ).id
    })
    await this.fetchSuppliers({ projectId: this.$route.params.projectId }).then(
      response => {
        this.suppliersResults = response
      },
    )
    await this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
  },
  methods: {
    ...mapActions('products', ['fetchProducts']),
    ...mapActions('expenses', ['addExpense', 'fetchSuppliers']),
    ...mapActions('groupedExpenses', [
      'fetchExpensesTypeCatalogs',
      'fetchGroupedExpenses',
    ]),
    validateForm() {
      this.$refs.expensesValidation.validate().then(success => {
        if (success) {
          this.expense.expense_records = this.expense.expense_records.map(
            record => {
              if (record.expense_type_catalog_id === this.materialCatalogId) return record

              const catalogName = this.expenseTypeCatalogHistory[this.expenseTypeCatalogHistory.length - 1].name
              return { ...record, name: catalogName }
            },
          )

          this.addExpense(this.expense).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('expenseAdded'),
                icon: 'SendIcon',
                variant: 'success',
              },
            })
            this.$router.push({
              name: 'allExpenses',
              params: { projectId: this.$route.params.projectId },
            })
          })
        }
      })
    },
    pushExpense() {
      this.expense.expense_records.push({
        expense_type_catalog_id: '',
        name: '',
        quantity: 0,
        unit_price: 0,
        iva_percentage: 16,
        supplier: '',
        brand: '',
        description: '',
        sku: '',
        expense_status: 'paid',
        expense_date: '',
        expense_create_type: this.$route.query.createType,
      })
    },
    spliceExpense(index) {
      this.expense.expense_records.splice(index, 1)
    },
    removeProduct(index) {
      this.expense.expense_records[index].name = ''
      this.expense.expense_records[index].quantity = ''
      this.expense.expense_records[index].brand = ''
      this.expense.expense_records[index].description = ''
      this.expense.expense_records[index].sku = ''
      this.expense.expense_records[index].unit_type = ''

      this.productQuery = ''
    },
    selectProduct(product, index) {
      this.expense.expense_records[index].name = product.name
      this.expense.expense_records[index].quantity = product.quantity
      this.expense.expense_records[index].brand = product.brand
      this.expense.expense_records[index].description = product.description
      this.expense.expense_records[index].sku = product.sku
      this.expense.expense_records[index].unit_type = product.unit_type
    },
    selectNewMaterial() {
      this.expense.expense_records[
        this.newMaterialIndex
      ].name = this.newMaterial.name
      this.expense.expense_records[
        this.newMaterialIndex
      ].brand = this.newMaterial.brand
      this.expense.expense_records[
        this.newMaterialIndex
      ].description = this.newMaterial.description
      this.newMaterial = {}
    },
    setStatus(record, status) {
      record.expense_status = status
    },
    backgroundForStatus(status) {
      switch (status) {
        case 'paid':
          return 'bg-custom-success'
        case 'credit':
          return 'bg-custom-success'
        case 'expired':
          return 'bg-custom-danger'
        case 'received':
          return 'bg-custom-success'
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-custom-success {
  background-color: #dff2e7;
  color: #3ecc7e;
}
.bg-custom-danger {
  background-color: #f29b9b;
  color: #bc3921;
}
</style>
