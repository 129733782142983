<template>
  <b-modal
    id="editCategoryModal"
    ref="editCategoryModal"
    hide-footer
    title="Editar Categoría"
  >
    <b-form-group
      label="Nombre de la Categoría"
      class="d-flex flex-column"
    >
      <b-form-input
        v-model="newCategory.name"
        class="mb-1"
        placeholder="Introduce el nombre"
      />
    </b-form-group>
    <b-form-group
      label="Ícono"
      class="d-flex flex-column"
    >
      <b-dropdown
        text=""
        variant="primary"
        class="m-2 "
        toggle-class="icon-select"
        no-caret
      >
        <template #button-content>
          <feather-icon
            v-if="newCategory.icon"
            :icon="newCategory.icon"
            size="24"
            class=""
          />
          <span v-else>{{ 'Selecciona un ícono' }}</span>
        </template>
        <div
          v-for="(group, index) in iconGroups"
          :key="index"
        >
          <h4 header>
            {{ group.label }}
          </h4>
          <div class="dropdown-grid">
            <b-dropdown-item
              v-for="icon in group.icons"
              :key="icon"
              class="expensesTypes__menu--item d-flex flex-column align-items-center"
              @click="selectIcon(icon)"
            >
              <feather-icon
                :icon="icon"
                class="mr-2 icon"
                style="padding: 8px;"
              />
            </b-dropdown-item>
          </div>

        </div>

      </b-dropdown>
    </b-form-group>

    <b-button
      variant="primary"
      class="btn-tour-skip mr-1"
      :disabled="!newCategory.name || !newCategory.icon"
      @click="editCategory()"
    >
      <span class="align-middle">Actualizar</span>
    </b-button>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
    projectId: {
      type: String,
      default: () => (''),
    },
    parentId: {
      type: String,
      default: () => (''),
    },
  },
  data() {
    return {
      newCategory: {
        id: '',
        name: '',
        icon: '',
        active_status: true,
        parent_id: '',
      },
      iconOptions: [],
      iconGroups: [
        {
          label: 'General',
          icons: ['HomeIcon', 'UserIcon', 'SettingsIcon', 'BellIcon', 'CalendarIcon'],
        },
        {
          label: 'Social',
          icons: ['MessageCircleIcon', 'HeartIcon', 'ThumbsUpIcon', 'ThumbsDownIcon', 'UsersIcon'],
        },
        {
          label: 'Interfaz',
          icons: ['CheckCircleIcon', 'XCircleIcon', 'AlertCircleIcon', 'ChevronRightIcon', 'ChevronLeftIcon'],
        },
        {
          label: 'Negocios',
          icons: ['BarChartIcon', 'BriefcaseIcon', 'CreditCardIcon', 'DollarSignIcon', 'ShoppingCartIcon'],
        },
        {
          label: 'Medios',
          icons: ['CameraIcon', 'FilmIcon', 'MusicIcon', 'MicIcon', 'PlayCircleIcon'],
        },
        {
          label: 'Tecnología',
          icons: ['CpuIcon', 'CodeIcon', 'MonitorIcon', 'SmartphoneIcon', 'WifiIcon'],
        },
      ],

    }
  },
  mounted() {
    this.newCategory = {
      id: this.category.id,
      name: this.category.name,
      icon: this.category.icon,
      active_status: true,
      parent_id: '',
    }
  },
  methods: {
    ...mapActions('groupedExpenses', [
      'editExpensesTypeCatalogs',
    ]),
    selectIcon(icon) {
      this.newCategory.icon = icon
    },
    getAllIcons() {
      return this.iconGroups.reduce((acc, group) => acc.concat(group.icons), [])
    },
    editCategory() {
      if (this.newCategory.name && this.newCategory.icon) {
        this.newCategory.parent_id = this.parentId
        this.editExpensesTypeCatalogs({ projectId: this.projectId, expenseType: this.newCategory }).then(() => {
          this.category = null
          this.$emit(this.parentId ? 'update' : 'refetch')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('expenseTypeEdited'),
              icon: 'SendIcon',
              variant: 'success',
            },
          })
        })
        // Limpiar el formulario después de agregar la categoría
        this.newCategory.name = ''
        this.newCategory.icon = ''
        this.newCategory.id = ''

        // Cerrar el modal
        this.$refs.editCategoryModal.hide()
      }
    },
  },
}
</script>
  <style >
  .icon-select{
   text-align: center;
   justify-content: center;
   align-items: center;
   display: flex;
  }
  .dropdown-menu {
    overflow: scroll;
    height: 15rem;
    padding: 5px;
  }
  </style>
