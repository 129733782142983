<template>
  <div class="expensesTypes__menu">
    <div v-if="!record.expense_type_catalog_id">
      Selecciona una Categoria
    </div>
    <div :class="['expensesTypes__menu--header', level === 0 ? 'justify-content-end' : 'justify-content-between' ]">
      <span
        v-if="level !== 0"
        @click="handleBackClick"
      >
        <feather-icon
          size="24"
          icon="ArrowLeftCircleIcon"
        />
      </span>
      <div class="d-flex align-items-center flex-column mb-3" v-if="level !== 0">
        <span
          class="icon"
        >
          <feather-icon
            :icon="getCategorySelected().icon"
          />
        </span>
        <span>
          {{ getCategorySelected().name }}
        </span>
      </div>
      <span @click="isEditing = !isEditing">
        <feather-icon
          size="24"
          :icon="isEditing ? 'XIcon' : 'EditIcon'"
        />
      </span>
    </div>
    <div class="dropdown-grid">
      <div
        v-for="category in expensesTypes"
        :key="category.id"
        :class="['expensesTypes__menu--item d-flex flex-column align-items-center', isEditing ? 'icon-vibrate' : '']"
        @click="handleExpenseTypeClick(category)"
      >
        <span
          class="icon"
        >
          <feather-icon
            :icon="category.icon"
          />
        </span>
        <span
          class="text"
        >{{ category.name || '-' }}</span>
        <div
          v-if="isEditing"
          class="edit-options"
        >
          <span
            class="text mr-2"
            @click.stop="handleEditClick(category)"
          ><feather-icon icon="EditIcon" /></span>
          <span
            class="text"
            @click.stop="handleDeleteClick(category)"
          >
            <feather-icon icon="DeleteIcon" />
          </span>
        </div>
      </div>
      <div
        v-b-modal = "'addCategoryModal'+index"
        class="expensesTypes__menu--item d-flex flex-column align-items-center"
      >
        <span class="icon">{{ '+' }}</span>
        <span class="text">{{ 'Agregar Nueva' }}</span>
      </div>
    </div>
    <add-category-modal
      :id="'addCategoryModal'+index"
      :project-id="$route.params.projectId"
      :parent-id="record.expense_type_catalog_id"
      @refetch="$emit('refetch')"
      @update="getSubExpenseType"
    />
    <edit-category-modal
      v-if="expenseTypeToEdit"
      :project-id="$route.params.projectId"
      :parent-id="record.expense_type_catalog_id"
      :category="expenseTypeToEdit"
      @update="getSubExpenseType"
      @refetch="$emit('refetch')"
    />
    <confirmation-modal
      :id="`confirmDelete`"
      :variant="'danger'"
      @confirm="removeExpenseType()"
    >
      <template v-slot:content>
        {{ $t('deleteExpenseTypeConfirmation', {
          expenseType: expenseTypeToDelete.name
        }) }}
      </template>
    </confirmation-modal>
  </div>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import AddCategoryModal from './AddCategoryModal.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import EditCategoryModal from './EditCategoryModal.vue'

export default {
  components: {
    AddCategoryModal,
    ConfirmationModal,
    FeatherIcon,
    EditCategoryModal,
  },
  props: {
    index: {
      type: Number,
      default: () => (0),
    },
    record: {
      type: Object,
      default: () => ({}),
    },
    expenseTypeCatalogs: {
      type: Array,
      default: () => ([]),
    },
    history: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      isEditing: false,
      expenseTypeToDelete: null,
      expenseTypeToEdit: null,
    }
  },
  computed: {
    ...mapGetters('groupedExpenses', [
      'subExpenseType',
    ]),
    expensesTypes() {
      if (this.record.expense_type_catalog_id) {
        let subs = []
        subs = this.history[this.level - 1]?.subcategories
        return subs.map(expense => ({
          name: expense.name,
          id: expense.id,
          icon: expense?.icon || '🏠',
          subcategories: expense.subcategories || [],
        }))
      }
      return this.expenseTypeCatalogs.map(expense => ({
        name: expense.name,
        id: expense.id,
        icon: expense?.icon || '🏠',
        subcategories: expense.subcategories || [],
      }))
    },
    level() {
      return this.history.length
    },
  },
  methods: {
    ...mapActions('groupedExpenses', [
      'deleteExpensesTypesCatalog',
      'fetchSubExpenseTypeCatalog',
    ]),
    handleBackClick() {
      this.history.pop()
      const lastCat = this.history[this.level - 1] || null
      this.record.expense_type_catalog_id = lastCat?.id || null
      this.$emit('setExpenseType', lastCat?.name || '')
    },
    handleExpenseTypeClick(category) {
      this.record.expense_type_catalog_id = category.id
      this.$emit('setExpenseType', category.name)
      this.getSubExpenseType()
      // this.history.push(this.subExpenseType)
    },
    getCategorySelected() {
      return this.history[this.level - 1]
    },
    handleDeleteClick(category) {
      this.isEditing = false
      this.expenseTypeToDelete = category
      this.$bvModal.show('confirmDelete')
    },
    handleEditClick(category) {
      this.expenseTypeToEdit = category
      this.isEditing = false
      this.$bvModal.show('editCategoryModal')
    },
    removeExpenseType() {
      this.deleteExpensesTypesCatalog({ projectId: this.$route.params.projectId, expenseTypeId: this.expenseTypeToDelete.id }).then(() => {
        this.$emit('refetch')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('expenseTypeDeleted'),
            icon: 'SendIcon',
            variant: 'success',
          },
        })
      })
    },
    getSubExpenseType() {
      this.fetchSubExpenseTypeCatalog({ projectId: this.$route.params.projectId, expenseTypeId: this.record.expense_type_catalog_id }).then(data => {
        this.history.push(data)
      })
    },
  },
}
</script>
<style >
.expensesTypes__menu--header{
    display: flex;
    border-bottom: 1px solid black;
    height: 3rem;
    align-items: center;
}
.expensesTypes__menu--header span:hover{
    cursor: pointer;
}
.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px; /* Espacio entre las columnas */
}

.expensesTypes__menu--item {
  text-align: center;
  padding: 10px;
}
.expensesTypes__menu--item:hover {
 cursor: pointer;
}

.icon {
  font-size: 22px; /* Tamaño del ícono */
  border: 1px solid black;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text{
  font-size: 14px; /* Tamaño del texto */
  margin-top: 4px; /* Espacio entre el ícono y el texto */
}
.text > svg:hover{
  background-color: rgb(169, 150, 227);
}
/* Animación de vibración */
@keyframes vibrate {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-1px, 1px);
  }
  40% {
    transform: translate(1px, -1px);
  }
  60% {
    transform: translate(-1px, -1px);
  }
  80% {
    transform: translate(1px, 1px);
  }
  100% {
    transform: translate(0);
  }
}

/* Aplicar la animación de vibración a los íconos */
.icon-vibrate {
  display: inline-block;
  animation: vibrate 0.2s linear infinite; /* Duración de 0.2s, linear y repetición infinita */
}

</style>
